import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import ContactHero from "../components/ContactHero";

export default () => {
  const [email, setEmail] = useState("");
  useEffect(() => {
    setTimeout(() => {
      setEmail("info@wimmic.com");
    }, 500);
    return () => {};
  }, []);
  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="content">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <h1>QUALITY AND R&amp;D POLICY</h1>

                <p>
                  WIMMIC is an organization dedicated to offer products and
                  services of design of integrated circuits of RF (RFIC) and
                  microwaves (MMIC) for the aerospace, communications and
                  defense industry globally.
                </p>
                <p>
                  The general objective of WIMMIC is to establish itself from
                  the Canary Islands as one of the 10 benchmark companies in the
                  European panorama in our activity.
                </p>
                <p>
                  WIMMIC is committed to complying with the requirements of its
                  clients and stakeholders, regulatory constraints, and all
                  applicable legal requirements. To do this, it adopts
                  continuous improvement in its processes by implementing a
                  quality management and R&D system. The following commitments
                  are established:
                </p>

                <ul>
                  <li>
                    Develop and apply new technologies and methodologies that
                    improve our technical capacity and product improvement.
                  </li>
                  <li>
                    Implement and maintain a Management System establishing the
                    mechanisms that guarantee the continuous improvement of its
                    effectiveness.
                  </li>
                  <li>
                    Promote a culture of R&amp;D in the staff, promoting
                    creativity and teamwork.
                  </li>
                  <li>
                    Properly exploit and protect the results of our R&amp;D
                    activities.
                  </li>
                  <li>
                    Management's commitment to the implementation of the
                    Management System through the promotion of the necessary
                    resources for its purpose.
                  </li>
                  <li>
                    Establish quality and R&amp;D objectives that are
                    challenging and ensure the continuous improvement of our
                    work and its results.
                  </li>
                  <li>
                    Promote R&amp;D opportunities in our field of activity
                  </li>
                </ul>

                <p>
                  To this end, the Management considers motivation and training
                  for Quality and R&amp;D as priorities. This Policy serves as a
                  frame of reference to establish and review the objectives of
                  Quality and R&amp;D and is therefore implemented, kept up to
                  date, reviewed and communicated to all employees and
                  stakeholders.
                </p>

                <p className="has-text-centered">
                  Las Palmas de Gran Canaria, August 19th, 2019 <br /> The
                  Management
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactHero />
    </Layout>
  );
};
